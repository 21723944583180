import React from 'react'

const LoadMore = ({ handleClick }) => (
  <nav className="pagination">
    <button
      type="button"
      className="pagination__button"
      onClick={handleClick}
    >
      Load more posts
    </button>
  </nav>
)

export default LoadMore