import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'

const PostPreview = ({ node: { excerpt, frontmatter: { path, title, published, image, tags }}}) => (
  <article
    className="post post-list__item"
    itemProp="blogPost"
    itemScope
    itemType="http://schema.org/BlogPosting"
  >
    <header className="post__header">
      <h2 className="post__header__title" itemProp="name">
        <Link to={path} className="post__header__title__link" itemProp="url">
          {title}
        </Link>
      </h2>

      <time
        dateTime="{published}"
        className="post__header__date"
        itemProp="datePublished"
      >
        {published}
      </time>

      {tags.length > 0 && (
        <div className="tags post__header__tags">
          <h3 className="tags__title">
            Posted in: {' '}
          </h3>
          <ul className="tags__list">
            {tags.map((tag, i) => (
              <React.Fragment key={i}>
                <li className="tags__list__item">
                  <Link
                    to={`/tag/${tag.slug}`}
                    className="tags__list__item__link"
                  >
                    {tag.name}
                  </Link>
                </li>
                {i < tags.length - 1 && <>&nbsp;</>}
              </React.Fragment>
            ))}
          </ul>
        </div>
      )}
    </header>

    <div
      className="post__content"
      itemProp="articleBody"
    >
      <Link to={path} className="post__header__title__link">
        <Image
          fluid={image.childImageSharp.fluid}
          alt={title}
          className="post__content__cover-image"
          itemProp="image"
        />
      </Link>

      <p className="post__content__excerpt">
        {excerpt} {' '}
        <Link to={path} className="post__content__excerpt__read-more">
          Read more {' '}
          <span className="post__content__read-more__context">
            of "{title}"
          </span>
        </Link>
      </p>
    </div>
  </article>
)

export default PostPreview