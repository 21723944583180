import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PostPreview from '../components/post-preview'
import LoadMore from '../components/load-more'

const paramFromUrl = key => {
  let value = null

  if (
    typeof window !== 'undefined' &&
    (window.location.search.includes(`?${key}=`) || window.location.search.includes(`&${key}=`))
  ) {
    window.location.search.substring(1).split('&').forEach(params => {
      const [k, v] = params.split('=')

      if (k === key) {
        value = v.split('+').join(' ')
      }
    })
  }

  return value
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        postsPerPage
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___published] }
      filter: { frontmatter: { status: { eq: "published" }}}
    ) {
      edges {
        node {
          frontmatter {
            path
            slug
            title
            status
            published(formatString: "dddd Do MMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            tags {
              slug
              name
            }
          }
          excerpt(pruneLength: 280)
        }
      }
    }
  }
`

const includesQuery = (target, query) => target.toLowerCase().includes(query.toLowerCase())

const includesTag = (target, query) => target.find(({ slug, name }) => (
  includesQuery(slug, query) ||
  includesQuery(name, query)
))

let updateUrlTimeout

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      q: paramFromUrl('q') || '',
      page: props.pageContext.page || 1,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handlePopstate = this.handlePopstate.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  handleSearch(e) {
    window.clearTimeout(updateUrlTimeout)

    const nextQ = e.target.value
    const nextQWithoutSpaces = nextQ.trim().split(' ').join('+')
    const to = nextQ === '' ? '/' : `/?q=${nextQWithoutSpaces}`

    updateUrlTimeout = window.setTimeout(() => {
      window.history.pushState({ q: nextQWithoutSpaces, page: 1 }, null, to)
    }, 1500)

    this.setState({ q: nextQ, page: 1 })
  }

  handleLoadMore() {
    const nextPage = this.state.page + 1
    const nextState = { q: this.state.q, page: nextPage }
    const to = `/page/${nextPage}/${this.state.q === '' ? '' : `?q=${this.state.q}`}`

    window.history.pushState(nextState, null, to)

    this.setState(nextState)
  }

  resetState() {
    const nextState = { q: '', page: 1 }
    window.history.pushState(nextState, null, '/')
    this.setState(nextState)
  }

  handlePopstate() {
    this.setState({
      q: '',
      page: 1,
      ...(window.history.state || {}),
    })
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handlePopstate)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopstate)
    window.clearTimeout(updateUrlTimeout)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.q !== nextState.q || this.state.page !== nextState.page
  }

  render() {
    const { data: {
      allMarkdownRemark: { edges },
      site: { siteMetadata: { title, description, postsPerPage }},
    }} = this.props

    const numberOfPosts = this.state.page * postsPerPage

    const allPosts = edges
      .filter(({ node: { frontmatter: { slug, title, tags }}}) => (
        slug !== 'about' &&
        slug !== 'places' &&
        (includesQuery(title, this.state.q) || includesTag(tags, this.state.q))
      ))
      
    const posts = allPosts
      .slice(0, numberOfPosts)
      .map(({ node }, i) => <PostPreview node={node} key={i} />)

    return (
      <Layout
        homeCallback={this.resetState}
        og={[
          { property: 'og:type', content: 'website' },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
        ]}
        twitter={[
          { name: 'card', content: 'summary' },
          { name: 'title', content: title },
          { name: 'description', content: description },
        ]}
        prev={this.state.page > 1 ? `/${this.state.page > 2 ? `page/${this.state.page - 1}/` : ''}` : null}
        next={allPosts.length > posts.length ? `/page/${this.state.page + 1}/` : null}
      >
        <form
          autoComplete="off"
          className="search"
          method="get"
          action="/"
          onSubmit={e => e.preventDefault()}
        >
          <input
            className="search__input"
            type="text"
            placeholder="Search posts..."
            name="q"
            value={this.state.q}
            onChange={this.handleSearch}
          />
          <noscript>Enable Javascript to search posts</noscript>
        </form>
        <main role="main" className="post-list">
          {posts}
          {allPosts.length > numberOfPosts && <LoadMore handleClick={this.handleLoadMore} />}
          {posts.length === 0 && (
            <h1 className="no-results">
              Sorry, no posts found for the term <strong>{this.state.q}</strong>.
            </h1>
          )}
        </main>
      </Layout>
    )
  }
}

export default IndexPage
